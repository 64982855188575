import { EventType, BestFitAIItemType } from '../enums';
import { IEvent } from '@studyportals/event-aggregation-service-interface';

export class ProfileQuestionnaireBestFitAIEvent implements IEvent {
	public static EventType: string = EventType.PROFILE_QUESTIONNAIRES_BESTFIT_AI_EVENT;
	public readonly timestamp: Date;
	public readonly eventType: string = ProfileQuestionnaireBestFitAIEvent.EventType;
	public readonly item: { type: BestFitAIItemType; value: string, progressPercentage?: number };

	public constructor(item: { type: BestFitAIItemType; value: string, progressPercentage?: number }) {
		this.item = item;
		this.timestamp = new Date();
	}
}
