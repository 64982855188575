export enum QuestionnaireType {
	BESTFIT = 'bestfit',
	PROFILE_PAGE = 'profile_page',
	LOCALIZATION_SETTINGS = 'localization_settings',
	CONFIRM_NATIONALITY_SETTINGS = 'confirm_nationality_settings',
	RECOMMENDATIONS = 'recommendations',
	ONBOARDING = 'onboarding',
	GLOBAL_ACCESS_INITIATIVE = 'global_access_initiative',
	START_DATE = 'start_date',
	SCHOLARSHIPS_ELIGIBILITY = 'scholarships_eligibility',
	FJS_DISCOVER = 'fjs_discover',
	STUDY_PREFERENCES = 'study_preferences',
	BESTFIT_AI = 'bestfit_ai',
}
