import { StudyPreferencesAttendanceType, StudyPreferencesEventTrackType } from '@/interfaces/enums/study-preferences-event-track-type';
import { StudyPreferencesFieldType } from '@/interfaces/enums/study-preferences-filed-type';
import { Product, Action, Tracker, ITrackingData } from '@studyportals/datalake-event-tracker';
import { IExperimentTrackingData } from '@studyportals/datalake-event-tracker/dist/src/interfaces/tracking-data.interface';
import { QuestionnaireType } from 'interfaces';

export default class SnowplowTracker {
	private trackerWithoutGA: Tracker;
	private trackerWithGA: Tracker;

	constructor() {
		this.trackerWithoutGA = new Tracker(Product.PROFILE_QUESTIONNAIRES);
		this.trackerWithGA = new Tracker(Product.PROFILE_QUESTIONNAIRES, true);
	}

	public trackQuestionImpressionWithoutGA(questionnaire: string, step: string): void {
		const data: ITrackingData = {
			action: Action.IMPRESSION,
			label: questionnaire,
			property: step,
		};

		this.trackerWithoutGA.trackStructuredEvent(data);
	}

	public trackAnswerClickWithoutGA(question: string, answer: string): void {
		const answerWithouHiffens = this.replaceHyphensWithUnderscores(answer);

		const data: ITrackingData = {
			action: Action.SELECT,
			label: question,
			property: answerWithouHiffens,
		};

		this.trackerWithoutGA.trackStructuredEvent(data);
	}

	public trackNavigationActionWithoutGa(action: string, questionnaire: string): void {
		const data: ITrackingData = {
			action: Action.CLICK,
			label: questionnaire,
			property: `navigation_${action}`,
		};

		this.trackerWithoutGA.trackStructuredEvent(data);
	}

	public trackQuestionnaireStatusWithGA(status: string, questionnaire: string): void {
		const data: ITrackingData = {
			action: Action.IMPRESSION,
			label: status,
			property: questionnaire,
		};

		this.trackerWithGA.trackStructuredEvent(data);
	}

	public trackOnboardingHelpfulContentClickWithoutGA(property: string): void {
		const data: ITrackingData = {
			action: Action.CLICK,
			label: `${QuestionnaireType.ONBOARDING}_helpful_content`,
			property: this.convertStringToSnakeCase(property),
		};

		this.trackerWithoutGA.trackStructuredEvent(data);
	}


	public replaceHyphensWithUnderscores(input: string): string {
		return input.replace(/-/g, '_');
	}

	private convertStringToSnakeCase(unformattedString: string): string {
		return unformattedString
			.replace(/\W+/g, ' ')
			.split(/ |\B(?=[A-Z])/)
			.map((word) => word.toLowerCase())
			.join('_');
	}

	public trackCPIRegistrationWithGA(
		label: StudyPreferencesEventTrackType,
		property?: StudyPreferencesFieldType | StudyPreferencesAttendanceType | string,
	): void {
		const data: IExperimentTrackingData = {
			category: Product.EXPERIMENT,
			action: Action.CLICK,
			label: `${QuestionnaireType.STUDY_PREFERENCES}_${label}`,
			property,
		};

		this.trackerWithGA.trackExperimentEvent(data);
	}
}
