import globals from "@/utils/globals";
import { AuthenticationServiceReadyEvent } from "@studyportals/authentication-service-interfaces";
import { ISubscriber } from "@studyportals/event-aggregation-service-interface";
import store from "..";


export class AuthenticationServiceReadyPlugin implements ISubscriber<AuthenticationServiceReadyEvent> {

	public startListening(): void {
		globals.eventAggregationService.subscribeTo(AuthenticationServiceReadyEvent.EventType, this, true);
	}

	public notify(event: AuthenticationServiceReadyEvent): void {
		store.mutations.setAuthenticationService(event.authenticationServiceApplication);
	}

}