import { QuestionnaireType, ProfileQuestionnairesCpiModalClosed } from "interfaces";
import { StudyPreferencesEventTrackType } from "../interfaces/enums/study-preferences-event-track-type";
import globals from "./globals";
import { ModalManager, SimpleModal } from '@studyportals/modal';
import { Modal } from '@studyportals/modal/src/domain/modal';
import { EventAggregationService } from "../infrastructure/event-aggregation-service";

export default class ModalHandler {

	private modals: Modal[] = [];
	public modalManager = new ModalManager();

	constructor(private eventAggregationService: EventAggregationService) { }

	public opensPopupQuestionnaireModal(identifier: string): void {
		this.createPopQuestionnaire(identifier);

		this.modalManager.open(this.modals[identifier] as Modal);
	}

	private createPlaceholderForModal(identifier: string): HTMLDivElement {
		const placeholder = document.createElement('div');
		placeholder.classList.add('ProfileQuestionnaire');
		placeholder.setAttribute('data-questionnaire', identifier);
		return placeholder;
	}

	private createModal(identifier: string, placeholder: HTMLDivElement): void {
		this.modals[identifier] = new SimpleModal(placeholder, {
			cssClassName: 'QuestionnairePopup',
			destroyOnClose: true,
			onClose: (): void => this.onModalClose(identifier as QuestionnaireType),
		});
	}

	private onModalClose(identifier: QuestionnaireType): void {
		if (identifier === QuestionnaireType.STUDY_PREFERENCES) {
			this.handleCloseCPIModal();
		}
	}

	private handleCloseCPIModal(): void {
		this.enableScroll();
		this.eventAggregationService.publishTo(ProfileQuestionnairesCpiModalClosed.EventType, new ProfileQuestionnairesCpiModalClosed());
		globals.snowplowTracker.trackCPIRegistrationWithGA(StudyPreferencesEventTrackType.CLOSE);
	}

	private enableScroll(): void {
		document.body.style.overflow = 'auto';
	}

	private createPopQuestionnaire(identifier: string): void {
		const placeholder = this.createPlaceholderForModal(identifier);

		this.createModal(identifier, placeholder);

		this.addQuestionnaireEventListenerToClosePopUp(identifier, 'questionnaire_completed');
		this.addQuestionnaireEventListenerToClosePopUp(identifier, 'questionnaire_closed');
	}


	private addQuestionnaireEventListenerToClosePopUp(identifier: string, eventIdentifier: string): void {
		document.addEventListener(eventIdentifier, (e: any) => {
			this.closePopup(e, identifier);
		});
	}

	private closePopup(e: any, identifier: string): void {
		if (e.detail.identifier === identifier) {
			this.modalManager.close(this.modals[identifier] as Modal);
		}
	}
}