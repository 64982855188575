import { NavigationDirections } from '../domain/enums/navigation-directions';
import { NavigationStates } from '../domain/enums/navigation-states';
import { QuestionnaireType } from '../../interfaces/enums/questionnaire-type';
import { Answer, QuestionDTO } from '../interfaces/answer.interface';
import { IActions } from '../interfaces/store.interfaces';
import globals from '../utils/globals';
import { v4 } from 'uuid';
import store from '.';
import { INavigationState } from '@/interfaces/navigation-state.interface';
import { LoginStatusCheckerPlugin } from './plugins/login-status-checker-plugin';
import { StudentQuestionsAnswersPlugin } from './plugins/student-questions-answers-plugin';
import { UICountryIdClient } from './plugins/iu-country-id-plugin';
import { AuthenticationServiceReadyPlugin } from './plugins/authentication-plugin';

const actions: IActions = {
	setAreDependenciesLoaded: async (areDependenciesLoaded: boolean) => {
		store.mutations.setAreDependenciesLoaded(areDependenciesLoaded);
		if (areDependenciesLoaded) {
			const loginStatusCheckerPlugin = new LoginStatusCheckerPlugin();
			const studentQuestionsAnswersPlugin = new StudentQuestionsAnswersPlugin();
			const uiCountryIdClient = new UICountryIdClient();
			const authenticationServiceReadyPlugin = new AuthenticationServiceReadyPlugin();
			uiCountryIdClient.startListening();
			authenticationServiceReadyPlugin.startListening();
			await studentQuestionsAnswersPlugin.initialize();
			await loginStatusCheckerPlugin.initialize();
			store.mutations.setIsReady(true);
		}
	},
	saveAnswer: async (question: QuestionDTO<Answer>) => {
		store.mutations.saveAnswer(question);
		await globals.studentRepository.update(question);

		document.dispatchEvent(new CustomEvent('profile_question_answered', { detail: question }));

		await store.actions.updateProfileCompleteness();
	},
	updateQuestions: (questions: QuestionDTO<Answer>[]) => {
		const updatedQuestions: QuestionDTO<Answer>[] = [];

		if (typeof questions === 'undefined') {
			return;
		}
		if (questions.length <= 0) {
			return;
		}

		questions.forEach((questionDTO) => {
			updatedQuestions[questionDTO.type] = questionDTO;
		});

		store.mutations.updateQuestions(updatedQuestions);
	},

	updateAllQuestions: async () => {
		const questionTypes = store.getters.questionKeys();
		const questions = await globals.studentRepository.retrieveQuestions(questionTypes);

		store.actions.updateQuestions(questions);
		await store.actions.updateProfileCompleteness();
	},

	signUpStep: (step: any) => {
		store.mutations.signUpStep(step);
	},

	signUpQuestionnaire: ({ type, index, requestedSteps, onlyFinishOnSignIn }) => {
		const identifier: string = v4();

		store.mutations.signUpQuestionnaire({
			identifier,
			type,
			index,
			requestedSteps,
			onlyFinishOnSignIn,
		});

		return identifier;
	},

	nextQuestion: (identifier: string) => {
		if (store.getters.navigationState(identifier) === NavigationStates.NEXT_HIDDEN) {
			return;
		}

		store.mutations.setDirection({ identifier, direction: NavigationDirections.FORWARD });

		if (store.getters.isLastStep(identifier) && !store.getters.onlyFinishOnSignIn(identifier)) {
			store.actions.setQuestionnaireFinishedFlag({
				identifier,
				flag: true,
			});
			return;
		}

		if (store.getters.isLastStep(identifier) && store.getters.onlyFinishOnSignIn(identifier) && store.getters.isLoggedIn()) {
			store.actions.setQuestionnaireFinishedFlag({
				identifier,
				flag: true,
			});
			return;
		}

		store.mutations.nextQuestion(identifier);
	},

	previousQuestion: (identifier: string) => {
		const questionaires = store.getters.questionnaires(identifier);
		const steps = questionaires.steps;
		const index = questionaires.index;

		store.mutations.setDirection({ identifier, direction: NavigationDirections.BACKWARD });

		if (!steps || typeof steps[index - 1] === 'undefined') {
			return;
		}

		store.mutations.previousQuestion(identifier);
	},

	setIndex: ({ identifier, index }: { identifier: string; index: number }) => {
		store.mutations.setIndex({ identifier, index });
	},

	updateStepVisibility: ({ identifier, key, show }: { identifier: string; key: string; show: boolean }) => {
		store.mutations.updateStepVisibility({ identifier, key, show });
	},

	setQuestionnaireFinishedFlag: ({ identifier, flag }: { identifier: string; flag: boolean }) => {
		if (typeof store.getters.questionnaires(identifier) === 'undefined') {
			return;
		}

		if (store.getters.navigationState(identifier) === NavigationStates.NEXT_HIDDEN) {
			return;
		}
		store.mutations.setQuestionnaireFinishedFlag({ identifier, flag });

		if (flag) {
			globals.eventBus.emit('questionnaireFinished', store.getters.questionnaires(identifier).type);
		}
	},

	userLoggedIn: () => {
		store.mutations.setLoggedInFlag(true);
	},

	updateUserCurrency: async () => {
		const currency = await globals.studentRepository?.getStudentCurrency();

		if (currency) {
			store.mutations.setUserCurrency(currency);
		}
	},

	updateUserNationalityIsoCode: async () => {
		const userCountryISO = await globals.studentRepository?.getNationalityCountryISO();

		if (!userCountryISO) return;

		store.mutations.setUserNationalityIsoCode(userCountryISO);
	},

	updateUserLocalCurrency: async () => {
		const userCountryISO = await globals.studentRepository?.getNationalityCountryISO();

		const { currencyGetFromCountry } = await import('@studyportals/static-domain-data/currencies/currency-get-from-country');

		if (userCountryISO) {
			const currencyISO = currencyGetFromCountry(userCountryISO)?.iso;

			if (!currencyISO) {
				return;
			}

			store.mutations.setUserLocalCurrency(currencyISO);
		}
	},

	setNavigationState: ({ identifier, state }: INavigationState) => {
		store.mutations.setNavigationState({
			identifier,
			navigationState: state,
		});
	},

	signOffQuestionnaire: (identifier: QuestionnaireType) => {
		store.mutations.signOffQuestionnaire(identifier);
	},

	updateProfileCompleteness: async () => {
		const repository = globals.profileCompletenessRepository;
		store.mutations.setProfileCompleteness(await repository.getCompleteness());
		store.mutations.setProfileCompletenessFirstUnansweredQuestion(await repository.getFirstIncompleteQuestion());
	},
};

export default actions;
