declare const STAGE: string;

const rollbarConfig = {
	accessToken: '914267423bfc42e3aa44b17a8afe9e69',
	captureUncaught: false,
	captureUnhandledRejections: false,
	checkIgnore: window['rollbarIgnore'],
	payload: {
		environment: STAGE,
	},
};

export default {
	install: (app: any): void => {
		const rollbar = window && window['rollbar'] && window['rollbarIgnore'] ? new window['rollbar'](rollbarConfig) : null;
		window['rollbar_PQ'] = rollbar;
		app.config.errorHandler = (error): void => {
			if (STAGE === 'prd' && rollbar !== null) {
				rollbar.error(error);
			}
			throw error;
		};
	},
};
