import { IAnonymousStudentService } from '@studyportals/anonymous-student-interfaces';
import { IStudent, StudentField } from '@studyportals/studentdomain';
import { QuestionType } from '../../interfaces/enums/question-type';
import { Answer, IGPA, IName, QuestionDTO } from '../interfaces/answer.interface';

export class StudentDatabaseClient {
	private anonymousStudentService: IAnonymousStudentService;

	constructor(anonymousStudentService: IAnonymousStudentService) {
		this.anonymousStudentService = anonymousStudentService;
	}

	public async saveStudentData(studentData: IStudent): Promise<void> {
		return await this.anonymousStudentService.setStudentData(studentData);
	}

	public async saveSingleAnswer(question: QuestionDTO<Answer>): Promise<void> {
		const wasEdgeCase = await this.saveEdgeCaseSeparately(question.type, question.answer);

		if (wasEdgeCase) {
			return;
		}

		return this.anonymousStudentService.setStudentData(question.answer as IStudent);
	}

	public async retrieveStudentData(studentFields: StudentField[]): Promise<IStudent> {
		return await this.anonymousStudentService.getStudentData(studentFields);
	}

	private async saveEdgeCaseSeparately(type: string, answer: Answer): Promise<boolean> {
		switch (type as QuestionType) {
			case QuestionType.NAME:
				await this.updateName(answer as IName);
				return true;
			case QuestionType.DISCIPLINE_INTERESTS:
				await this.updateCollection(answer, StudentField.INTERESTS_DISCIPLINES);
				return true;
			case QuestionType.COUNTRY_INTERESTS:
				await this.updateCollection(answer, StudentField.INTERESTS_COUNTRIES);
				return true;
			case QuestionType.FIELD_OF_STUDY:
				await this.updateCollection(answer, StudentField.DISCIPLINES);
				return true;
			case QuestionType.ATTENDANCE:
				await this.updateCollection(answer, StudentField.ATTENDANCE);
				return true;
			case QuestionType.GPA:
				await this.updateGPA(answer as IGPA);
				return true;
		}

		return false;
	}

	private async updateName(answer: IName): Promise<void> {
		if (!this.anonymousStudentService) {
			return;
		}

		await this.anonymousStudentService.setName(answer.name);
	}

	private async updateGPA(answer: IGPA): Promise<void> {
		if (!this.anonymousStudentService) {
			return;
		}

		const gpaType: string = answer.gpa.current_type;

		await this.anonymousStudentService.setGPA(gpaType, answer.gpa[gpaType]);
	}

	private async updateCollection(answer: Answer, field: StudentField): Promise<void> {
		if (!this.anonymousStudentService) {
			return;
		}

		const studentData = await this.anonymousStudentService.getStudentData([field]);
		const answerItems = answer[field] as number[];
		const currentItems = (studentData[field] as number[]) || [];
		const itemsToAdd = this.getArrayDifference<number>(answerItems, currentItems);
		const itemsToRemove = this.getArrayDifference<number>(currentItems, answerItems);

		if (itemsToAdd.length > 0) {
			await this.anonymousStudentService.addToCollection(field, itemsToAdd);
		}
		if (itemsToRemove.length > 0) {
			await this.anonymousStudentService.removeFromCollection(field, itemsToRemove);
		}
	}

	private getArrayDifference<T>(firstArray: T[], secondArray: T[]): T[] {
		return firstArray.filter((element: T) => {
			return secondArray.indexOf(element) === -1;
		});
	}
}
